export default [
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/modules/home/components/HomeComponent')
    },
    {
        path: '/capacitaciones',
        name: 'capacitaciones',
        component: () => import(/* webpackChunkName: "CapacitacionesView" */ '@/modules/capacitaciones/components/CapacitacionesComponent')
    },
    {
        path: '/inducciones',
        name: 'inducciones',
        component: () => import(/* webpackChunkName: "InduccionesView" */ '@/modules/inducciones/components/InduccionesComponent')
    },
    {
        path: '/checklist',
        name: 'checklist',
        component: () => import(/* webpackChunkName: "ExpedienteChecklistComponent" */ '@/modules/expediente/checklist/components/ExpedienteChecklistComponent')
    },
    {
        path: '/checklist-revision',
        name: 'checklist-revision',
        component: () => import(/* webpackChunkName: "ChecklistRevisionView" */ '@/modules/expediente/checklistRevision/views/ChecklistRevisionView')
    },
    {
        path: '/vacaciones',
        name: 'vacaciones',
        component: () => import(/* webpackChunkName: "VacacionesEmployeeView" */ '@/modules/incidencias/vacaciones/views/VacacionesEmployeeView')
    },
    {
        path: '/vacaciones-revision',
        name: 'vacaciones-revision',
        component: () => import(/* webpackChunkName: "RevisarSolicitudesView" */ '@/modules/incidencias/vacaciones/views/RevisarSolicitudesView')
    },
    {
        path: '/ausentismos',
        name: 'ausentismos',
        component: () => import(/* webpackChunkName: "AusentismosEmployeeView" */ '@/modules/incidencias/ausentismos/views/AusentismosEmployeeView')
    },
    {
        path: '/ausentismos-revision',
        name: 'ausentismos-revision',
        component: () => import(/* webpackChunkName: "RevisarSolicitudesView" */ '@/modules/incidencias/ausentismos/views/AusentismosAdminView')
    },
    {
        path: '/solicitar-prima-vacacional',
        name: 'solicitar-prima-vacacional',
        component: () => import(/* webpackChunkName: "SolicitarPrimaView" */ '@/modules/incidencias/primaVacacional/views/PrimaVacacionalEmployeeView.vue')
    },
]