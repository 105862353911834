import { defineStore } from 'pinia'
import seNicodemusApi from '@/api/seNicodemusApi'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        status: 'authenticating',
        jwt: ''
    }),
    actions: {
        async loginUser(usuario, password){
            try{
                const datos = {
                    usuario,
                    password
                }
                const {data} = await seNicodemusApi.post('/auth/login', datos)
                localStorage.setItem('jwt', data.access_token)
                this.status = 'authenticated'
                return{ ok: true, message: ''}
            }
            catch(error){
                const errObj = error.response.data
                const errDetail = Object.hasOwn(errObj,'error') ? errObj.error : ''
                this.status = 'not-authenticated'
                const codigo = error.response.status
                const msjeSwal = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña`: 
                `Ocurrió un error interno en el servidor, intenta más tarde [${errDetail}]`
                return {ok: false, message: msjeSwal}
            }
        },
        async checkAuthentication(){
            try{
                const {data} = await seNicodemusApi.get('usuarios/validateToken')
                const {usuario, id_usuario, id_empresa} = data
                const userData = {
                    usuario,
                    id_usuario,
                    id_empresa
                }
                localStorage.setItem('payload', JSON.stringify(userData))
                if(parseInt(id_empresa) !== 0){
                    localStorage.setItem('empraisLogged', parseInt(id_empresa))
                }
                this.status = 'authenticated'
                return {ok: true}
            }
            catch(error){
                return {ok: false, message: error.response}
            }
        },
        logout(){
            this.status = 'not-authenticated'
            this.jwt = ''
            localStorage.removeItem('jwt')
            localStorage.removeItem('payload')
            localStorage.removeItem('empraisLogged')
        },
        async isAdmin(){
            try{
                const {data} = await seNicodemusApi.get('usuarios/is-admin')
                console.log('IS ADMIN', data)
                return {ok: true, message: ''}
            }
            catch(error){
                return {ok: false, message: error.response}
            }
        },
        async roleUser(){
            try{
                const {data} = await seNicodemusApi.get('usuarios/role')
                console.log('ROLE USER', data)
                return {ok: true, message: '', response: data}
            }
            catch(error){
                return {ok: false, message: error.response, response: null}
            }
        },
        async forgotPassword(datos){
            try{
                const {data} = await seNicodemusApi.post('/auth/forgot', datos)
                console.log('FORGOT PASSWORD USER', data)
                return {ok: true, message: '', response: data}
            }
            catch(error){
                return {ok: false, message: error.response, response: null}
            }
        },
        async checkRecoverToken(datos){
            try{
                const {data} = await seNicodemusApi.post('/auth/recover-check-token', datos)
                console.log('CHECK RECOVER PASSWORD TOKEN', data)
                return {ok: true, message: '', response: data}
            }
            catch(error){
                return {ok: false, message: error.response, response: null}
            }
        },
        async changePassword(datos){
            try{
                const {data} = await seNicodemusApi.post('/auth/change-password', datos)
                console.log('CHANGE PASSWORD', data)
                return {ok: true, message: '', response: data}
            }
            catch(error){
                return {ok: false, message: error.response, response: null}
            }
        },
    }
})